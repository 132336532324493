<template>
  <Modal id="modal_tdb_explanation">
    <template #title>
      {{ $t('commande.informations-liees-aux-commandes') }}
    </template>
    <template #subtitle>
      {{ $t('commande.outil-permet-suivre-commandes-de-votre-etablissement') }}
    </template>
    <template #content>
      <ul>
        <li
          v-for="(info, index) in tdbInfos"
          :key="index"
          class="bloc-infos"
        >
          <div
            class="infos text-regular"
            :class="info.color"
          >
            <span>{{ info.label }}</span>
            <strong>{{ info.value | spaceNumber }}</strong>
          </div>
          <p
            class="explanation text-medium"
            v-html="info.help"
          />
        </li>
      </ul>
    </template>
    <template #footer>
      <ButtonClassic
        variant="solid"
        color="secondary"
        :label="$t('general.j-ai-compris')"
        icon="right"
        @click="hide()"
      >
        <template #right-icon>
          <UilThumbsUp />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal } from "@lde/core_lde_vue";

import { UilThumbsUp } from "@iconscout/vue-unicons";

/**
 * Modale d'explication des informations sur le tableau de bord.
 */
export default {
  name: "ModalTdbExplanation",
  components: {
    Modal,
    ButtonClassic,
    UilThumbsUp,
  },
  props: {
    /**
     * Informations du tableau de bord.
     */
    tdbInfos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    /**
     * Ferme la modale.
     */
    hide() {
      this.$modal.hide("modal_tdb_explanation");
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_tdb_explanation.scss";
</style>
