<template>
  <section id="home_slider">
    <h2 class="s5 bold title-separator">
      <span>{{ $t('home.a-la-une-de-cristal') }}</span>
    </h2>
    <div class="grid-zone">
      <div id="menu_slider">
        <p class="text-medium">
          {{ $t('home.bons-plans-rassembles-ici') }}
        </p>
        <ul id="menu_categories">
          <li
            v-for="filter, index in sliderCatalogues"
            :key="filter.key"
          >
            <button
              class="button text-small bold"
              data-bs-toggle="collapse"
              :data-bs-target="`#${filter.key}_${index}`"
              :disabled="accordionOpenedIndex === index"
              @click="accordionOpenedIndex = index"
            >
              {{ filter.name }}
            </button>
            <div
              :id="`${filter.key}_${index}`"
              class="menu-catalogue text-medium collapse"
              data-bs-parent="#menu_categories"
            >
              <li
                v-for="catalogue in filter.catalogues"
                :key="catalogue.key"
                :class="{
                  'active': filter.key === currentSliderCatalogue.type
                    && catalogue.key === currentSliderCatalogue.catalogue,
                }"
              >
                <button
                  class="button bold"
                  :disabled="filter.key === currentSliderCatalogue.type
                    && catalogue.key === currentSliderCatalogue.catalogue"
                  @click="fetchSliderArticles(filter.key, catalogue)"
                >
                  {{ catalogue.name }}
                </button>
              </li>
            </div>
          </li>
        </ul>
        <ButtonGroup v-if="!isSliderLoading">
          <ButtonClassic
            variant="solid"
            color="white"
            icon="left"
            :disabled="isSliderMoving"
            @click="changeIndex(sliderIndex - 1)"
          >
            <template #left-icon>
              <UilAngleLeftB />
            </template>
          </ButtonClassic>
          <ButtonClassic
            variant="solid"
            color="white"
            icon="left"
            :disabled="isSliderMoving"
            @click="changeIndex(sliderIndex + 1)"
          >
            <template #left-icon>
              <UilAngleRightB />
            </template>
          </ButtonClassic>
        </ButtonGroup>
      </div>
      <div
        v-if="isSliderLoading"
        class="flex-vcenter loader"
      >
        <InfiniteLoader />
      </div>
      <Slider
        v-else-if="sliderProducts.length"
        ref="slider"
        :autoplay="false"
        no-pagination
      >
        <template #slider-items>
          <SliderCard
            v-for="i in totalPages"
            :key="i"
          >
            <template #custom>
              <div
                v-for="n in 3"
                :key="n"
              >
                <ProductItem
                  v-if="sliderProducts[3*(i-1)+n-1]"
                  v-model="sliderProducts[3*(i-1)+n-1]"
                  small
                />
              </div>
            </template>
          </SliderCard>
        </template>
      </Slider>
      <p
        v-else
        class="no-results s5 text-center"
      >
        {{ $t("produit.aucun-produit-de-ce-type-disponible") }}
      </p>
    </div>
  </section>
</template>

<script>
import {
  Slider,
  SliderCard,
  InfiniteLoader,
  ButtonGroup,
  ButtonClassic,
} from "@lde/core_lde_vue";
import ProductItem from "@/components/products/ProductItem.vue";

import { UilAngleLeftB, UilAngleRightB } from "@iconscout/vue-unicons";
import { mapGetters } from "vuex";
import Api from "@/modules/axios";

/**
 * Slider de la Homepage.
 */
export default {
  name: "HomeSlider",
  components: {
    UilAngleLeftB,
    UilAngleRightB,
    Slider,
    SliderCard,
    ProductItem,
    InfiniteLoader,
    ButtonGroup,
    ButtonClassic,
  },
  data() {
    return {
      isSliderLoading: true,
      isSliderMoving: false,
      isMovingTimer: 0,
      sliderProducts: [],
      currentSliderCatalogue: {},
      accordionOpenedIndex: 0,
      sliderIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["hasPerm"]),
    totalPages() {
      return Math.ceil(this.sliderProducts.length / 3);
    },
    sliderCatalogues() {
      const cat = [
        {
          name: this.$t("filtre.nouveautes"),
          key: "news",
          catalogues: [
            {
              name: this.$t("menu.ressources-numeriques"),
              key: "numerique",
              url: "manuel_numerique",
              perm: "view_manuelnumerique",
            },
            // TODO: en attente de savoir si on a besoin du papier
            // {
            //   name: this.$t("menu.livres-et-manuels-papier"),
            //   key: "papier",
            //   url: "papier",
            //   perm: "view_articlepapier",
            // },
            {
              name: this.$t("menu.fournitures-et-papeterie"),
              key: "fourniture",
              url: "fourniture",
              perm: "view_fourniture",
            },
          ],
        },
        {
          name: this.$t("filtre.promotions"),
          key: "promos",
          catalogues: [
            {
              name: this.$t("menu.fournitures-et-papeterie"),
              key: "fourniture",
              url: "fourniture",
              perm: "view_fourniture",
            },
          ],
        },
        // {
        //   name: "Coup de coeur",
        //   key: "coup_de_coeur",
        //   catalogues: [],
        // }
      ];

      cat.forEach((c) => {
        c.catalogues = c.catalogues.filter((catalogue) => this.hasPerm(catalogue.perm));
      });

      return cat;
    },
  },
  mounted() {
    const firstType = this.sliderCatalogues[0];
    this.currentSliderCatalogue = { type: firstType.key, catalogue: firstType.catalogues[0] };

    this.fetchSliderArticles(this.currentSliderCatalogue.type, this.currentSliderCatalogue.catalogue);
  },
  methods: {
    /**
     * Récupère les articles aléatoire.
     * @param {String} type Définit si on veut les nouveautés ou les promotions.
     * @param {String} catalogue Catalogue duquel on veut afficher les produits.
     */
    fetchSliderArticles(type, catalogue) {
      this.currentSliderCatalogue = { type, catalogue: catalogue.key };
      this.isSliderLoading = true;
      Api().get(`/${catalogue.url}/randoms/?nb=12&only_${type}=1`)
        .then((res) => {
          this.sliderProducts = res.data;
          this.isSliderLoading = false;
        });
    },
    /**
     * Change l'index du slider
     * @param {Number} index index du slider.
     */
    changeIndex(index) {
      const { isMoving, handlePagination } = this.$refs.slider;
      if (!isMoving) {
        this.isSliderMoving = true;
        let page = index;
        if (index === -1) {
          page = this.totalPages - 1;
        }
        if (index === this.totalPages) {
          page = 0;
        }
        this.sliderIndex = page;
        handlePagination(page, true);

        // Gestion de timer pour changement de slide
        if (this.isMovingTimer) {
          clearInterval(this.isMovingTimer);
        }

        this.isSliderMoving = true;
        // Lorsqu'on change de slide, on bloque le changement pour éviter le spam
        this.isMovingTimer = setInterval(() => {
          this.isSliderMoving = false;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/home/home_slider.scss";
</style>
