<template>
  <section
    id="home_widgets"
    class="grid-zone"
  >
    <div
      id="acces_rapides"
      class="grid-4"
    >
      <h1 class="title s4 bold">
        {{ $t("home.acces-rapides") }}
      </h1>
      <ul class="text-right">
        <li>
          <ButtonClassic
            variant="solid"
            :label="$t('recherche.faire-une-recherche-avancee')"
            size="large"
            icon="left"
            @click="$store.dispatch('toggleAdvSearch')"
          >
            <template #left-icon>
              <IconRechercheAvancee />
            </template>
          </ButtonClassic>
        </li>
        <li>
          <ButtonClassic
            variant="solid"
            :label="$t('liste.commencer-une-saisie-rapide')"
            size="large"
            icon="left"
            @click="$router.push({ name: 'listes_devis_listes', query: { saisieRapide: true, }, })"
          >
            <template #left-icon>
              <UilBookmark />
            </template>
          </ButtonClassic>
        </li>
      </ul>
    </div>
    <div
      v-if="hasPerm('view_manuelnumerique')"
      class="grid-4"
    >
      <HorizontalSearchNum variant="widget" />
    </div>
    <div
      v-if="hasPerm('view_articlepapier')"
      class="grid-4"
    >
      <HorizontalSearchPap variant="widget" />
    </div>

    <template v-if="slidesToDisplay.small && slidesToDisplay.small.length">
      <Slider
        v-for="slide in slidesToDisplay.small"
        :key="slide.id"
        small
      >
        <template #slider-items>
          <SliderCard
            :title="{ text: slide.titre, color: slide.titre_couleur, }"
            :headline="{ text: slide.accroche, color: slide.accroche_couleur, }"
            :description="{ text: slide.description, color: slide.description_couleur, }"
            :background="{ color: slide.fond_couleur, image: slide.fond_image, }"
          >
            <template
              v-if="slide.logo"
              #header-image
            >
              <img
                :src="slide.logo"
                :alt="$t('general.alt-logo-editeur')"
              />
            </template>
            <template
              v-if="slide.illustration"
              #illustration
            >
              <img
                :src="slide.illustration"
                :alt="$t('general.alt-image-illustration')"
              />
            </template>
            <template #buttons>
              <ButtonClassic
                v-if="slide.btn_1_lien"
                v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_1_label, ]"
                balise-type="a"
                :label="slide.btn_1_label"
                variant="solid"
                color="primary"
                size="small"
                icon="left"
                :ext-link="slide.btn_1_lien"
              >
                <template #left-icon>
                  <UilArrowRight />
                </template>
              </ButtonClassic>
              <ButtonClassic
                v-if="slide.btn_2_lien"
                v-matomo-log-click="['slider-btn-2', slide.titre, slide.btn_2_label, ]"
                balise-type="a"
                :label="slide.btn_2_label"
                variant="solid"
                color="primary"
                size="small"
                icon="left"
                :ext-link="slide.btn_2_lien"
              >
                <template #left-icon>
                  <UilArrowRight />
                </template>
              </ButtonClassic>
            </template>
          </SliderCard>
        </template>
      </Slider>
    </template>

    <template v-if="slidesToDisplay.large && slidesToDisplay.large.length">
      <Slider
        v-for="slide in slidesToDisplay.large"
        :key="slide.id"
      >
        <template #slider-items>
          <SliderCard
            :title="{ text: slide.titre, color: slide.titre_couleur, }"
            :headline="{ text: slide.accroche, color: slide.accroche_couleur, }"
            :description="{ text: slide.description, color: slide.description_couleur, }"
            :background="{ color: slide.fond_couleur, image: slide.fond_image, }"
          >
            <template
              v-if="slide.logo"
              #header-image
            >
              <img
                :src="slide.logo"
                :alt="$t('general.alt-logo-editeur')"
              />
            </template>
            <template
              v-if="slide.illustration"
              #illustration
            >
              <img
                :src="slide.illustration"
                :alt="$t('general.alt-image-illustration')"
              />
            </template>
            <template #buttons>
              <ButtonClassic
                v-if="slide.btn_1_lien"
                v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_1_label, ]"
                balise-type="a"
                :label="slide.btn_1_label"
                variant="solid"
                color="primary"
                size="small"
                icon="left"
                :ext-link="slide.btn_1_lien"
              >
                <template #left-icon>
                  <UilArrowRight />
                </template>
              </ButtonClassic>
              <ButtonClassic
                v-if="slide.btn_2_lien"
                v-matomo-log-click="['slider-btn-2', slide.titre, slide.btn_2_label, ]"
                balise-type="a"
                :label="slide.btn_2_label"
                variant="solid"
                color="primary"
                size="small"
                icon="left"
                :ext-link="slide.btn_2_lien"
              >
                <template #left-icon>
                  <UilArrowRight />
                </template>
              </ButtonClassic>
            </template>
          </SliderCard>
        </template>
      </Slider>
    </template>
  </section>
</template>

<script>
import { Slider, SliderCard, ButtonClassic } from "@lde/core_lde_vue";

import HorizontalSearchNum from "@/components/search/HorizontalSearchNum.vue";
import HorizontalSearchPap from "@/components/search/HorizontalSearchPap.vue";

import { UilArrowRight, UilBookmark } from "@iconscout/vue-unicons";
import IconRechercheAvancee from "@/components/icons/IconRechercheAvancee.vue";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue de la page d'accueil du site après connexion.
 */
export default {
  name: "HomeWidgets",
  components: {
    Slider,
    SliderCard,
    HorizontalSearchNum,
    HorizontalSearchPap,
    ButtonClassic,
    UilArrowRight,
    UilBookmark,
    IconRechercheAvancee,
  },
  data() {
    return {
      slides: [],
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isHorsMarche", "hasPerm"]),
    slidesToDisplay() {
      if (!this.slides) {
        return { small: [], large: [] };
      }

      let slidesToDisplay = this.slides.filter((slide) => (slide.page === "accueil"));
      if (!this.isHorsMarche) {
        slidesToDisplay = slidesToDisplay.filter((s) => (
          this.organismeActuel?.marches.some((marche) => s.marches.includes(marche.id))
        ));
      } else {
        slidesToDisplay = slidesToDisplay.filter((slide) => slide.sans_marche);
      }
      return {
        small: slidesToDisplay.filter((slide) => slide.petite_taille).slice(0, 2), // On ne veut que 2 petits
        large: slidesToDisplay.filter((slide) => !slide.petite_taille).slice(0, 1), // On ne veut qu'un grand
      };
    },
  },
  created() {
    this.fetchSliders();
  },
  methods: {
    /**
     * Récupère les sliders et sépare les larges de petits.
     */
    fetchSliders() {
      Api().get("/slider/")
        .then((res) => {
          this.slides = res.data.results;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/home/home_widgets.scss";
</style>
