<template>
  <PageContent class="home">
    <template
      v-if="hasPerm('can_liste_to_devis')"
      #second-line
    >
      <template v-if="!isStatsLoading">
        <template v-for="(data, index) in dataBlocs">
          <hr
            v-if="data.separator"
            :key="index"
          />
          <router-link
            v-else
            :key="data.label"
            :to="data.link"
          >
            <DataBloc
              :label="data.label"
              :color="data.color"
              variant="icon-with-number"
              small
            >
              <template #icon>
                <component :is="data.icon" />
              </template>
              <template #number>
                {{ data.value }}
              </template>
            </DataBloc>
          </router-link>
        </template>
        <button
          class="button flex-vcenter"
          @click="$modal.show('modal_tdb_explanation')"
        >
          <UilInfoCircle size="16" />
        </button>
        <ModalTdbExplanation :tdb-infos="dataBlocs.filter((info) => !info.separator)" />
      </template>
      <template v-else>
        <InfiniteLoader />
      </template>
    </template>

    <template #content>
      <HomeMessages />
      <HomeWidgets />
      <HomeSlider />

      <!-- <div class="grid-zone">
        <Testimonials :testimonials="testimonials" />
        </div> -->

      <div
        v-if="!isGE"
        class="grid-zone"
      >
        <LdeHelp
          v-matomo-log-click="['assistance_home', ]"
          v-bind="helpAttributes"
        />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  LdeHelp,
  Testimonials,
  PageContent,
  InfiniteLoader,
  copyToClipboard,
} from "@lde/core_lde_vue";

import HomeSlider from "@/components/home/HomeSlider.vue";
import DataBloc from "@/components/establishments/DataBloc.vue";
import HomeWidgets from "@/components/home/HomeWidgets.vue";
import HomeMessages from "@/components/home/HomeMessages.vue";
import ModalTdbExplanation from "@/components/modals/ModalTdbExplanation.vue";

import IconFileDevis from "@/components/icons/IconFileDevis.vue";
import IconStarHourglass from "@/components/icons/IconStarHourglass.vue";

import {
  UilCheckCircle,
  UilSyncExclamation,
  UilInfoCircle,
  UilFileExport,
  UilFileCheck,
} from "@iconscout/vue-unicons";
import IconFileDevisCheck from "@/components/icons/IconFileDevisCheck.vue";

import config from "@/config";

import { mapGetters } from "vuex";

import Api from "@/modules/axios";
import { changePageTitle } from "@/modules/utils";
import typeMarcheTraduction from "@/modules/trad_utils";

/**
 * Vue de la page d'accueil du site après connexion.
 */
export default {
  name: "Home",
  components: {
    PageContent,
    HomeSlider,
    HomeMessages,
    ModalTdbExplanation,
    DataBloc,
    HomeWidgets,
    Testimonials,
    LdeHelp,
    InfiniteLoader,
    UilInfoCircle,
  },
  data() {
    return {
      stats: {},
      isStatsLoading: true,
      testimonials: [
        {
          id: 1,
          nom: "Tony N.",
          statut: "Utilisateur LDE",
          img: "h_junior.svg",
          texte: "Bien que je regrette le bel orange, l’esthétique est plus agréable, la navigation plus fluide, et "
          + "les rubriques sont bien plus claires&nbsp;!",
        },
        {
          id: 2,
          nom: "Rayane B.",
          statut: "Utilisateur LDE",
          img: "h_junior_masque.svg",
          texte: "Il est bien plus agréable de rechercher et de sélectionner des produits&nbsp;! Les infos sont plus "
          + "lisibles et cela facilite la compréhension.",
        },
        {
          id: 3,
          nom: "Olivier S.",
          statut: "Utilisateur LDE",
          img: "h_senior.svg",
          texte: "Une navigation plus performante pour passer nos commandes en toute autonomie.",
        },
      ],
      controller: new AbortController(),
    };
  },
  computed: {
    ...mapGetters([
      "organismeActuel",
      "country",
      "user",
      "isGE",
      "hasPerm",
      "hasPerms",
      "isHorsMarche",
      "urlHotline",
    ]),
    isDe() {
      return this.country === "de";
    },
    helpAttributes() {
      const attr = {
        // Pas de traduction pour la chaîne en FR car non visible pour DE
        buttonLabel: this.isDe ? config.mails.lde : "Posez-nous votre question",
      };
      if (this.expandedSidebar) {
        attr.label = this.$t("general.assistance");
      }
      if (!this.isDe) {
        attr.baliseType = "a";
        attr.extLink = this.urlHotline;
      }
      return attr;
    },
    dataBlocs() {
      const isValideur = this.hasPerm("can_liste_to_devis") && !this.hasPerm("can_view_as_maitre_compta");
      const isDOMaitreCompta = this.hasPerms(["can_liste_to_devis", "can_view_as_maitre_compta"]);

      const { devis, commande } = this.stats;

      if (!isValideur && this.isHorsMarche) {
        return [];
      }

      let res = [];

      if (devis) {
        res = [
          {
            label: this.$t("devis.devis-a-valider"),
            value: this.isHorsMarche
              ? devis.devis_soumis_count + devis.devis_a_valider_count
              : devis.devis_a_valider_count,
            color: "blue",
            icon: IconStarHourglass,
            link: { name: "listes_devis_devis" },
            visible: isValideur,
            help: this.$t("devis.devis-a-valide-help"),
          },
          {
            label: this.$t("devis.devis-a-soumettre"),
            value: devis.devis_a_soumettre_count,
            color: "blue",
            icon: UilFileExport,
            link: { name: "listes_devis_devis" },
            visible: !this.isHorsMarche && isValideur,
            help: typeMarcheTraduction().devisDevisASoumettreHelp,
          },
          {
            label: this.$t("devis.devis-a-modifier"),
            value: devis.devis_attente_modif_count,
            color: "warning",
            icon: UilSyncExclamation,
            link: { name: "listes_devis_devis" },
            visible: !this.isHorsMarche && isValideur,
            help: typeMarcheTraduction().devisDevisAModifierHelp,
          },
          { separator: isValideur },
          {
            label: typeMarcheTraduction().devisDevisSoumisARegion,
            value: devis.devis_soumis_count,
            color: "info",
            icon: IconFileDevis,
            link: { name: "listes_devis_devis" },
            visible: !this.isHorsMarche && !this.hasPerm("can_view_as_maitre_compta"),
            help: typeMarcheTraduction().devisDevisSoumisARegionHelp,
          },
          { separator: this.isHorsMarche && isValideur },
          {
            label: this.$t("devis.devis-transmis-a-region"),
            value: devis.devis_transmis_count,
            color: "info",
            icon: IconFileDevis,
            link: { name: "listes_devis_devis_soumis" },
            visible: isDOMaitreCompta,
            help: typeMarcheTraduction().devisDevisAApprouverHelp,
          },
          { separator: isDOMaitreCompta },
          {
            label: this.$t("devis.devis-a-commander"),
            value: devis.devis_valides_count,
            color: "blue",
            icon: IconFileDevisCheck,
            link: { name: "listes_devis_devis_soumis" },
            visible: isDOMaitreCompta,
            help: typeMarcheTraduction().devisDevisACommanderHelp,
          },
        ];
      }

      if (commande) {
        res = [...res, ...[
          {
            label: this.$tc("commande.commandes-en-cours", commande.commandes_en_cours_count),
            value: commande.commandes_en_cours_count,
            color: "positive",
            icon: UilFileCheck,
            link: { name: "commandes_factures_commandes" },
            visible: isValideur,
            help: this.isHorsMarche
              ? this.$t("commande.commande-en-cours-help-hm")
              : this.$t("commande.commande-en-cours-help-m"),
          },
          { separator: this.isHorsMarche && isValideur },
          {
            label: this.$tc("devis-commande.devis-rejete", devis.devis_rejete_count),
            value: devis.devis_rejete_count,
            color: "negative",
            icon: UilCheckCircle,
            link: { name: "listes_devis_devis" },
            visible: !this.isHorsMarche && isValideur,
            help: typeMarcheTraduction().devisDevisRejeteHelp,
          },
          { separator: isValideur },
        ]];
      }

      return res.filter(({ visible, separator }) => visible || separator);
    },
  },
  created() {
    changePageTitle(
      this.user?.contact?.prenom
        ? `${this.$t("general.bonjour")} ${this.user.contact.prenom},`
        : `${this.$t("general.bonjour")},`,
      this.$route,
    );
  },
  mounted() {
    if (this.hasPerm("can_liste_to_devis")) {
      const promises = [this.fetchDevisStats()];
      if (!this.hasPerm("can_view_as_maitre_compta")) {
        promises.push(this.fetchCommandeStats());
      }

      Promise.all(promises)
        .then(() => {
          this.isStatsLoading = false;
        });
    } else {
      this.isStatsLoading = false;
    }
  },
  methods: {
    copyToClipboard,
    /**
     * Récupère les données des devis pour le tdb.
     */
    fetchDevisStats() {
      return Api().get("devis/stats/").then(({ data }) => {
        this.stats.devis = data;
      }).catch((error) => {
        this.$toast.error({ title: error });
      });
    },
    /**
     * Récupère les données des commandes pour le tdb.
     */
    fetchCommandeStats() {
      return Api().get("commande/stats/").then(({ data }) => {
        this.stats.commande = data;
      }).catch((error) => {
        this.$toast.error({
          title: error,
        });
      });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_home.scss";
</style>
